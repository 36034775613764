<template>
  <base-layout :noPageHeader="true">
    <div class="walkthrough show reveal">
      <div class="walkthrough-pagination">
        <a :class="dotClass(0)"></a>
        <a :class="dotClass(1)"></a>
        <a :class="dotClass(2)"></a>
        <a :class="dotClass(3)"></a>
        <a :class="dotClass(4)" v-if="browser"></a>
      </div>

      <div class="lang-option">
        <select-language :type="'floating'"></select-language>
      </div>

      <div class="walkthrough-body">
        <ul class="screens animate">
          <li :class="screenClass(0)">
            <div class="body">
              <div class="title">
                <h1>UPTRADE <span>{{ lang.crypto_signals ?? "CRYPTO SIGNALS" }}</span></h1>
              </div>

              <div class="phone" v-show="ready && showScreen(0)">
                <img
                  src="@/assets/img/welcome/slide-1/main.png"
                  class="layer main"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-1/buy-1.png"
                  class="layer check check1"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-1/buy-2.png"
                  class="layer check check2"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-1/buy-3.png"
                  class="layer check check3"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-1/buy-4.png"
                  class="layer check check4"
                  alt=""
                />
              </div>
            </div>
          </li>
          <li :class="screenClass(1)">
            <div class="body">
              <div class="title">
                <h1>{{ lang.high_profit_spots ?? " HIGH PROFIT SPOTS" }}</h1>
              </div>

              <div class="phone" v-show="ready && showScreen(1)">
                <img
                  src="@/assets/img/welcome/slide-2/main.png"
                  class="layer main"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-2/signal1.png"
                  class="layer signal signal1 zoomInFadeIn"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-2/signal2.png"
                  class="layer signal signal2 zoomInFadeIn"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-2/signal3.png"
                  class="layer signal signal3 zoomInFadeIn"
                  alt=""
                />

                <img
                  src="@/assets/img/welcome/slide-2/signal4.png"
                  class="layer signal signal4"
                  alt=""
                />
              </div>
            </div>
          </li>
          <li :class="screenClass(2)">
            <div class="body">
              <div class="title">
                <h1>{{ lang.x35_futures ?? "3-50X FUTURES" }}</h1>
              </div>

              <div class="phone" v-show="ready && showScreen(2)">
                <img
                  src="@/assets/img/welcome/slide-3/main.png"
                  class="layer main"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-3/signal1.png"
                  class="layer signal signal1 zoomInFadeIn"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-3/signal2.png"
                  class="layer signal signal2 zoomInFadeIn"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-3/signal3.png"
                  class="layer signal signal3 zoomInFadeIn"
                  alt=""
                />

                <img
                  src="@/assets/img/welcome/slide-3/signal4.png"
                  class="layer signal signal4"
                  alt=""
                />
              </div>
            </div>
          </li>
          <li :class="screenClass(3)">
            <div class="body">
              <div class="title">
                <!-- <h1>EXIT WITH<br />SELL SIGNALS</h1> -->
                <h1>{{ lang.instant ?? "INSTANT" }} <span>{{ lang.signal_alerts ?? "SIGNAL ALERTS" }}</span></h1>
              </div>

              <div class="phone" v-show="ready && showScreen(3)">
                <img
                  src="@/assets/img/welcome/slide-4/main.png"
                  class="layer main"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update1.png"
                  class="layer signal signal1"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update2.png"
                  class="layer signal signal2"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update3.png"
                  class="layer signal signal3"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update4.png"
                  class="layer signal signal4"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update5.png"
                  class="layer signal signal5"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update6.png"
                  class="layer signal signal6"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update7.png"
                  class="layer signal signal7"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-4/update8.png"
                  class="layer signal signal8"
                  alt=""
                />
              </div>
            </div>
          </li>
          <li :class="screenClass(4)" v-if="browser">
            <div class="body">
              <div class="title">
                <!-- <h1>STAY<br />INFORMED</h1> -->
                <h1>{{ lang.welcome_page.slide_4_title }}</h1>
              </div>

              <div class="phone" v-show="ready && showScreen(4)">
                <img
                  src="@/assets/img/welcome/slide-5/main.png"
                  class="layer main"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-5/news1.png"
                  class="layer news news1"
                  alt=""
                />
                <img
                  src="@/assets/img/welcome/slide-5/news2.png"
                  class="layer news news2"
                  alt=""
                />
              </div>
            </div>
          </li>
        </ul>
        <button
          class="prev-screen"
          :disabled="slideIndex == 0"
          @click="prevScreen()"
        >
          <!-- <i class="icon-angle-left"></i> -->
          <ion-icon slot="icon-only" :icon="chevronBack"></ion-icon>
        </button>
      </div>
      <div class="walkthrough-footer">
        <button
          class="button next-screen"
          :disabled="slideIndex == maxSlideIndex"
          @click="nextScreen()"
        >
          {{ lang.welcome_page.next }}
          <ion-icon
            slot="icon-only"
            :icon="arrowForwardOutline"
            class="arrow-forward-icon point-right"
          ></ion-icon>
        </button>

        <button
          :class="{
            button: true,
            finish: true,
            close: true,
            active: slideIndex == maxSlideIndex,
          }"
          :disabled="slideIndex != maxSlideIndex"
          @click="getStarted()"
        >
          <!-- Get started -->
          {{ lang.welcome_page.get_started }}
        </button>
      </div>
    </div>
  </base-layout>
</template>


<script>
import { IonIcon, actionSheetController } from "@ionic/vue";
import {
  chevronForward,
  chevronBack,
  chevronDownOutline,
  logoGooglePlaystore,
  arrowForwardOutline,
} from "ionicons/icons";
import { Plugins } from "@capacitor/core";
import SelectLanguage from "../components/UI components/SelectLanguage.vue";
const { NativeHelper, Toast, Storage } = Plugins;

// import "@/assets/img/welcome/slide-1/main.png"

export default {
  inject: ["store"],
  data() {
    return {
      chevronForward,
      chevronBack,
      logoGooglePlaystore,
      chevronDownOutline,
      actionSheetController,
      arrowForwardOutline,
      slideIndex: 0,
      exit: "warn",
      ready: false,
      getAppView: false,
      deviceStore: null,
    };
  },
  components: {
    IonIcon,
    SelectLanguage
  },

  methods: {

    nextScreen() {
      if (this.slideIndex < this.maxSlideIndex) {
        this.slideIndex++;

        if (this.browser) {
          this.registerEvent("Next to slide " + String(this.slideIndex + 1));
        }
      }
    },

    prevScreen() {
      if (this.slideIndex > 0) {
        this.slideIndex--;

        if (this.browser) {
          this.registerEvent("Prev to slide " + String(this.slideIndex + 1));
        }
      }
    },

    dotClass(index) {
      let self = this;
      return {
        dot: true,
        active: self.slideIndex == index,
      };
    },

    screenClass(index) {
      let self = this;
      let _class = {
        screen: true,
        active: self.slideIndex == index,
      };
      _class["screen" + index] = true;
      return _class;
    },

    showScreen(index) {
      return this.slideIndex == index;
    },

    async warnExit() {
      await Toast.show({
        text: "Click exit again",
        duration: "long",
      });
    },

    async getStarted() {
      await Storage.set({
        key: "first_launch",
        value: "true",
      });

      let clipboardWorks = await this.store.getters.clipboardWorks();

      // alert(clipboardWorks);

      if (!clipboardWorks) {
        this.$router.push({
          path: "/allow-draw",
          replace: true,
        });
      } else {
        this.$router.push({
          path: "/spots",
          replace: true,
        });
      }
    },
  },

  computed: {
    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },

    maxSlideIndex() {
      return this.browser ? 4 : 3;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;
      let self = this;

      switch (action.type) {
        case "welcome-back":
          (async () => {
            if (self.slideIndex <= 0) {
              if (self.exit == "warn") {
                self.exit = "exit";
                self.warnExit();
                setTimeout(() => {
                  self.exit = "warn";
                }, 3500);
              } else {
                NativeHelper.moveToBackground();
              }
            } else {
              self.prevScreen();
            }
          })();

          break;
      }
    },
  },

  mounted() {},

  ionViewDidEnter() {
    let self = this;
    self.emitter.on("welcomeIsReady", () => {
      self.ready = true;
    });
  },

  created() {},
};
</script>





<style scoped lang="scss">
@mixin material-shadow() {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
}
$module-font-size: calc(14px + $extra-font-size);
$bezier: cubic-bezier(0.25, 0.8, 0.25, 1);

body {
  color: white;
  font-family: "Lato";
  font-weight: light;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // background: linear-gradient(
  //   150deg,
  //   #e6d3f9 0%,
  //   #e6d3f9 50%,
  //   #cea0f1 50%,
  //   #cea0f1 100%
  // );
}

.open-walkthrough {
  border: 0;
  background: var(--ion-color-primary);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: calc(12px + $extra-font-size);
  height: 40px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -60px;
}

// =================================================================================================
// Walkthrough
// =================================================================================================
.walkthrough {
  @include material-shadow();
  background: linear-gradient(
    to right bottom,
    var(--ion-color-primary-tint),
    var(--ion-color-primary)
  );
  border-radius: 0;
  display: none;
  flex-direction: column;
  flex: 0 0 auto;
  font-size: $module-font-size;
  overflow: hidden;
  transition: opacity 0.4s $bezier, transform 0.4s $bezier,
    box-shadow 0.4s $bezier;
  z-index: 1000;
  box-shadow: none;
  height: 100%;
  width: 100%;

  &.show {
    display: flex;
    opacity: 0;
    transform: translateY(72px);
  }

  &.reveal {
    opacity: 1;
    transform: translateY(0);
  }

  .walkthrough-body {
    align-items: center;
    display: flex;
    flex: 1;
    text-align: center;

    .prev-screen,
    .next-screen {
      align-self: stretch;
      background: none;
      border: 0;
      margin-top: 40px;
      color: rgba(white, 0.25);
      cursor: pointer;
      flex: 0 0 auto;
      font-size: calc(24px + $extra-font-size);
      opacity: 1;
      outline: none;
      padding: 16px;
      transform: scale(1);
      transition: transform 0.4s $bezier, color 0.4s $bezier,
        opacity 0.4s $bezier;
      z-index: 1000;
      position: absolute;
      height: 100%;
      margin-top: 0;

      &:hover,
      &:active {
        color: white;
        transform-origin: center;
        transform: scale(1.25);
      }

      &:disabled {
        opacity: 0;
      }
    }

    .prev-screen {
      order: 1;
      padding-left: 6px;
    }

    .next-screen {
      order: 3;
      right: 0;
    }

    .screens {
      flex: 1;
      align-self: stretch;
      position: relative;
      margin: 0 -16px;
      padding: 0;
      order: 2;

      .screen {
        position: absolute;
        list-style-type: none;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          #{lighten($primary-color, 50%)},
          $primary-color
        );

        .body {
          height: 100%;
          width: 100%;

          @if ($platform == "web") {
            margin-top: 23px;
          } @else {
            margin-top: 50px;
          }

          padding-left: 50px;
          padding-right: 50px;
          display: flex;
          flex-direction: column;

          .title {
            width: 234px;
            min-height: 96px;

            @media (max-width: 360px) {
              width: 178px;
            }

            h1 {
              text-align: left;
              color: #fff;
              margin-top: 0;
              font-size: calc(30px + $extra-font-size);
              // letter-spacing: 1px;
              font-weight: bold;
              margin-bottom: 30px;
              line-height: 30px;

              @media (max-width: 360px) {
                font-size: calc(26px + $extra-font-size);
              }

              span {
                font-size: calc(22px + $extra-font-size);
                display: block;
                // font-weight: 500;

                @media (max-width: 360px) {
                  font-size: calc(18px + $extra-font-size)
                }
              }
            }
          }

          .phone {
            flex-grow: 1;
            position: relative;

            .layer {
              width: 100%;
              height: 100%;
              object-fit: contain;
              position: absolute;
              top: 0;
              left: 0;
              object-position: top;
            }
          }
        }

        &.screen0 {
          .body {
            .phone {
              .layer.main {
                animation: slideFadeUp 0.5s $bezier forwards;
                animation-delay: 0.5s;
                opacity: 0;
              }

              .check {
                // animation: fadeIn 1s $bezier forwards;
                animation: slideFadeUpShort 1s $bezier forwards;
                opacity: 0;

                &.check1 {
                  animation-delay: 1.2s;
                }

                &.check2 {
                  animation-delay: 1.4s;
                }
                &.check3 {
                  animation-delay: 1.6s;
                }
                &.check4 {
                  animation-delay: 1.8s;
                }
                &.check5 {
                  animation-delay: 2s;
                }
                &.check6 {
                  animation-delay: 2.2s;
                }
              }
            }
          }
        }

        &.screen1,
        &.screen2 {
          .body {
            .phone {
              .layer.main {
                animation: slideFadeUp 0.5s $bezier forwards;
                animation-delay: 0.5s;
                opacity: 0;
              }

              .signal {
                &.zoomInFadeIn {
                  animation: zoomInFadeIn 1s $bezier forwards;
                  opacity: 0;
                  transform: scale(0.6);
                }

                &.signal1 {
                  animation-delay: 1.2s;
                  transform-origin: 0 30%;
                }

                &.signal2 {
                  animation-delay: 1.4s;
                  transform-origin: 0 35%;
                }

                &.signal3 {
                  animation-delay: 1.6s;
                  transform-origin: 30% 35%;
                }

                &.signal4 {
                  animation: slideFadeUpShort 1s $bezier forwards;
                  opacity: 0;
                  top: 50px;
                  animation-delay: 1.8s;
                }
              }
            }
          }
        }

        &.screen3 {
          .body {
            .phone {
              .layer.main {
                animation: slideFadeUp 0.5s $bezier forwards;
                animation-delay: 0.5s;
                opacity: 0;
              }

              .signal {
                animation: slideFadeUpShort 1s $bezier forwards;
                opacity: 0;
                top: 50px;

                &.signal1 {
                  animation-delay: 1.2s;
                }

                &.signal2 {
                  animation-delay: 1.4s;
                }
                &.signal3 {
                  animation-delay: 1.6s;
                }

                &.signal4 {
                  animation-delay: 1.8s;
                }

                &.signal5 {
                  animation-delay: 2s;
                }
                &.signal6 {
                  animation-delay: 2.2s;
                }
                &.signal7 {
                  animation-delay: 2.4s;
                }
                &.signal8 {
                  animation-delay: 2.6s;
                }
                &.signal9 {
                  animation-delay: 2.8s;
                }
              }
            }
          }
        }

        &.screen4 {
          .body {
            .phone {
              .layer.main {
                animation: slideFadeUp 0.5s $bezier forwards;
                animation-delay: 0.5s;
                opacity: 0;
              }

              .news {
                animation: slideFadeUpShort 1s $bezier forwards;
                opacity: 0;

                &.news1 {
                  animation-delay: 1.2s;
                }

                &.news2 {
                  animation-delay: 1.4s;
                }
              }
            }
          }
        }
      }
    }

    /* keyframe */
    @keyframes slideFadeUp {
      0% {
        opacity: 0;
        top: 100%;
      }

      100% {
        opacity: 1;
        top: 0;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes slideFadeUpShort {
      0% {
        opacity: 0;
        top: 50px;
      }

      100% {
        opacity: 1;
        top: 0;
      }
    }

    @keyframes zoomInFadeIn {
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    .media {
      background: rgba(white, 0.25);
      border-radius: 132px;
      height: 132px;
      margin: 32px auto;
      width: 132px;
    }

    h3 {
      font-size: calc(15px + $extra-font-size);
      line-height: 1.4em;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }

    p {
      line-height: 1.6em;
      font-size: calc(13px + $extra-font-size);
      margin-top: 16px;
      padding-top: 0;
      color: rgba(white, 0.8);
    }
  }

  .walkthrough-pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 12vh;
    margin: 0;
    position: absolute;
    right: 20px;

    @if ($platform == "web") {
      top: 30px;
    } @else {
      top: 50px;
    }

    z-index: 1;

    .dot {
      background: rgba(black, 0.25);
      border-radius: 8px;
      height: 8px;
      margin: 0 4px;
      transform: scale(0.75);
      transition: transform 0.4s $bezier, background 0.4s $bezier;
      width: 8px;

      &.active {
        background: white;
        transform: scale(1);
        transition-delay: 0.4s;
      }
    }
  }

  .walkthrough-footer {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-around;
    padding: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1;

    button {
      height: 60px;
      border: 0;
      background: var(--ion-color-primary);
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      border-radius: 0;
      color: white;
      flex: 1;
      font-size: calc(14px + $extra-font-size);
      margin: 0;
      outline: 0;
      padding: 12px;
      transition: opacity 0.4s $bezier, background 0.4s $bezier;
      cursor: pointer;
      &:active {
        background: #{lighten($primary-color, 5%)};
      }

      &:disabled {
        cursor: pointer;
      }

      &.finish {
        background: var(--ion-color-primary-shade);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        opacity: 0;
        transform: scale(0, 1);
        transform-origin: center;
        transition: opacity 0.4s $bezier, background 0.4s $bezier,
          transform 0.4s $bezier;
        &:hover {
          background: var(--ion-color-primary-shade);
        }
        &:active {
          background: var(--ion-color-primary-shade);
        }
        &.active {
          transform: scale(1, 1);
          opacity: 1;
        }
      }
    }
  }

  //Animation styles

  .screens {
    margin: 0;

    .media {
      .status-badge {
        left: 136px;
        opacity: 0;
        position: absolute;
        top: 104px;
        transform: scale(0);
        transition: opacity 0.4s $bezier, transform 0.4s $bezier;
        transition-delay: 0.6s;

        i {
          display: inline;
        }
      }

      &.logo {
        .logo {
          margin-top: 20px;
          opacity: 0;
          transform: translateY(-60px);
          transition: opacity 0.4s $bezier, transform 0.4s $bezier;
          width: 80px;
        }
      }
      .icon {
        position: absolute;
        opacity: 0;
        transform: translateY(-30px);
        transition: opacity 0.4s $bezier, transform 0.4s $bezier;
        width: 132px;
        left: 48px;
        top: 32px;
      }
      &.bars {
        .icon {
          transform: translate(40px, 20px);
          &:nth-of-type(2) {
            transform: scale(0.25);
            transform-origin: 30% 54%;
          }
          &:nth-of-type(3) {
            transform: scale(0.25);
            transform-origin: 30% 40%;
          }
          &:nth-of-type(4) {
            transform: scale(0.25);
            transform-origin: 30% 26%;
          }
        }
      }
      &.files {
        .icon {
          transform: translate(40px, 20px);
        }
      }
      &.comm {
        .icon {
          transform: scale(0.25);
          transform-origin: 29% 73%;

          &:nth-child(2) {
            transform-origin: 66% 85%;
          }
        }
      }
    }

    .screen {
      opacity: 0;
      position: absolute;
      transform: translateX(-72px);
      transition: all 0.4s $bezier;

      &.active {
        opacity: 1;
        transform: translateX(0) scale(1);
        transition-delay: 0.4s;

        ~ .screen {
          opacity: 0;
          transform: translateX(72px);
        }

        .media {
          .status-badge {
            opacity: 1;
            transform: scale(1.75);
          }

          &.logo {
            .logo {
              opacity: 0.8;
              transform: translateY(0);
              transition-delay: 0.6s;
            }

            .status-badge {
              transition-delay: 1s;
            }
          }

          &.books {
            .icon {
              opacity: 1;
              transform: translateY(0);
              transition-delay: 0.6s;
              &:nth-child(2) {
                transition-delay: 0.725s;
              }
              &:nth-child(3) {
                transition-delay: 0.85s;
              }
            }
            .status-badge {
              transition-delay: 1.4s;
            }
          }

          &.bars {
            .icon {
              opacity: 1;
              transform: translate(0) scale(1);
              transition-delay: 0.6s;
              &:nth-child(2) {
                transition-delay: 1.05s;
              }
              &:nth-child(3) {
                transition-delay: 0.925s;
              }
              &:nth-child(4) {
                transition-delay: 0.8s;
              }
            }
          }
          &.files {
            .icon {
              opacity: 1;
              transform: translateY(0);
              transition-delay: 0.9s;
              &:nth-child(3) {
                transition-delay: 0.8s;
              }
              &:nth-child(2) {
                transition-delay: 0.7s;
              }
              &:nth-child(1) {
                transition-delay: 0.6s;
              }
            }
            .status-badge {
              transition-delay: 1.6s;
            }
          }
          &.comm {
            .icon {
              opacity: 1;
              transform: scale(1);
              transition-delay: 0.6s;
              &:nth-child(2) {
                transition-delay: 0.8s;
              }
            }
            .status-badge {
              transition-delay: 1.6s;
            }
          }
        }
      }
    }
  }
}

.screen {
  width: 100%;

  > h3 {
    color: #ffffff !important;
    font-size: calc(20px + $extra-font-size) !important;
    font-weight: 800;

    &.h3-logo {
      .sub-title {
        color: rgba(255, 255, 255, 0.7);
        font-size: calc(12px + $extra-font-size);
      }
    }
  }

  > p {
    font-size: calc(14px + $extra-font-size) !important;
  }

  .media {
    height: 69vw !important;
    width: 69vw !important;
    margin: 50px auto !important;
    border-radius: 50% !important;
    max-width: 400px;
    max-height: 400px;

    @media (max-height: 768px) {
      margin: 35px auto !important;
    }

    @media (max-height: 568px) {
      margin: 20px auto !important;
    }

    .icons-wrap {
      position: relative !important;
    }

    &.logo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: 0 !important;
        width: 51vw !important;
      }
    }

    &.books {
      img {
        margin: 0 !important;
        width: 264px !important;
        left: 34px !important;
        top: 66px !important;
      }
    }
  }
}

img.icon {
  width: 100% !important;
  // left: 21px !important;
  left: 0 !important;
  // top: 56px !important;
  top: 0 !important;
}

#wave-comp {
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: auto;
}

.lang-option {
  position: absolute;

  @if ($platform == "web") {
    top: 55px;
  } @else {
    top: 75px;
  }

  right: 20px;
  z-index: 1;
}

ion-button#get-app {
  --background: #{$green};
  // --background: #06A77D;
}

ion-icon.arrow-forward-icon {
  position: relative;
  top: 2px;
}

#get-app-bottom-btn {
  background: #{darken($green, 0%)};

  ion-icon {
    position: relative;
    top: 2px;
  }
}
</style>